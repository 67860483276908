import { loginSecurityService , getCurrentUser, createUserFirstAccess, sendValidationCode, confirmValidationCode } from '@/common/securityserver';
import $router from "../router/router";
const SESSION_KEY_NAME = 'auth_user';

const state = {
  session: {},
  userAuth: null,
};

const getters = {
  user: (state) => state.session,
  getUserAuth(state) {
    return state.userAuth
  }
};

function isIosSafari(){
  return /iP(ad|od|hone)/i.test(navigator.userAgent) && /WebKit/i.test(navigator.userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(navigator.userAgent));
}

function isChrome() {
  return (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) || /ANDROID/i.test(navigator.userAgent.toUpperCase());
}

const actions = {
  userAuth(context, auth) {
    context.commit('setUserAuth', auth);
  },
  async serviceLogin(context, user) {

    user.browser = isIosSafari() ? 'Safari' : isChrome() ? 'Chrome' : '';

    const tokenSecurityService = await loginSecurityService(user);

    if (tokenSecurityService.error) {

      let errorMsg = tokenSecurityService.message;

      if (errorMsg == "O Termo de Uso não foi aceito.") {
        context.commit("auth", user);
        await $router.push("/termos");
        return;
      } else if (errorMsg == "ACCESS_VALIDATION_ERROR_MESSAGE") {
        await $router.push("/userNotEnableToAccess");
        return;
      } else if (errorMsg == "Usuário bloqueado.") {
        await $router.push("/userLocked");
        return;
      } else if (context.state.appCode === 'eu_protegido' && (errorMsg === "USER_SOCIAL_ID_NOT_EXISTS" || errorMsg === "VALID_ACCOUNT_BUT_NO_USER" || errorMsg === "ACCOUNT_LOGIN_NOT_FOUND")){
        if(!user.login){
          await $router.push('primeiro-acesso');
          return
        }else{
          await context.dispatch('createUserFirstAccess', user);
          return;
        }
      } else if(errorMsg == 'FINANCIAL_GROUP_NOT_PRESENT_OR_ACTIVE' || errorMsg == 'RH_PROTEGIDO_IS_DISABLED_APP_CONFIGURATION'){
        errorMsg = "Acesso negado. Por favor, contate o seu RH.";
      } else if (errorMsg === "INVALID_DEVICE") {
        context.commit('validateDevice', true);
        return;
      } else {
        errorMsg = "Acesso Inválido";
      }

      context.commit("showmsg", {
        text: errorMsg,
        type: "error"
      });

      return { error: true, text: errorMsg};

    }else{
      if(context.state.appCode === 'eu_protegido') {
        context.commit("email", user.login);
        context.commit("password", user.password);
      }
      context.commit("tinnyToken", tokenSecurityService.tinnyToken);
      context.commit("token", tokenSecurityService.token);
      await $router.push("/serviceLoginCallback");
    }
  },
  async login(context, data) {

    const tokenSecurityService = await loginSecurityService(data);
    if (tokenSecurityService.error) {
      return { error: true, text: tokenSecurityService.message };
    }else{
      return await context.dispatch('createSession', tokenSecurityService);
    }
  },
  async serviceSendValidationCode(context, email) {

    const response = await sendValidationCode(email);

    let mensagem = "Código enviado com sucesso!";
    const error = response.status !== 200;

    if (error) {
      if (response.status === 409 && response.data.message === "Por favor, aguarde 2 minutos antes de solicitar um novo código.") {
        return {
          error: false,
          pendingSeconds: response.data.seconds
        };
      }
      mensagem = "Erro ao enviar código";
    }

    context.commit("showmsg", {
      text: mensagem,
      type: error ? "error" : "success",
    });

    return { error: error, text: mensagem };
  },
  async serviceConfirmValidationCode(context, payload) {
    const response = await confirmValidationCode(payload);

    let mensagem = "Código validado com sucesso!";
    const error = response.status !== 200;
    if (error) {
      mensagem = "Erro ao validar o código enviado";
    }

    context.commit("showmsg", {
      text: mensagem,
      type: error ? "error" : "success"
    });

    return { error: error, text: mensagem };
  },
  async createSession(context, tokenSecurityService){
    const user = await getCurrentUser(tokenSecurityService);
    user.tokenSecurityService = tokenSecurityService;
    user.profilePicture = "https://www.pngfind.com/pngs/m/110-1102775_download-empty-profile-hd-png-download.png";

    const formattedSessionData = {
      name: user.name,
      email: user.email,
      profilePicture: user.profilePicture,
      tokenSecurityService: user.tokenSecurityService,
    };

    await context.dispatch('startSession', formattedSessionData);
    return true;
  },

  async startSession(context, sessionData) {
    const formattedUser = {
      nome: sessionData.name,
      email: sessionData.email,
      img: sessionData.profilePicture,
      dtcriacao: new Date().getTime(),
      tokenSecurityService: sessionData.tokenSecurityService,
    };

    localStorage.setItem(SESSION_KEY_NAME, JSON.stringify(formattedUser));

    context.commit('setSession', formattedUser);
  },
  logout(context) {
    localStorage.removeItem(SESSION_KEY_NAME);
    context.commit('updateMenuState', {
      left: false,
      leftmini: false,
      right: false,
    });
    context.commit('setSession', {});
  },
  checkAuthentication(context) {
    const user = JSON.parse(localStorage.getItem(SESSION_KEY_NAME) || '{}');
    const creationDate = user.dtcriacao;

    if (!creationDate) {
      return false;
    }
    context.commit('setSession', user);
    const sessionTolerance = 3600000;
    const difference = new Date().getTime() - creationDate;
    return difference <= sessionTolerance;
  },

  async createUserFirstAccess(context, newUser) {
    await createUserFirstAccess(newUser).then(user => {
      user.password = newUser.password;
      return loginSecurityService(user).then(async response => {
        context.commit("tinnyToken", response.tinnyToken);
        context.commit("token", response.token);
        await getCurrentUser(response.token).then(currentResponse => {
          if (currentResponse.error && currentResponse.message === 'O Termo de Uso não foi aceito.') {
            context.commit('auth', newUser);
            $router.push('/termos');
          } else {
            context.commit('token', response.token);
            $router.push('/serviceLoginCallback');
          }
        });
      })
    })
    .catch(error => {
      let message = "Erro ao criar usuário!";
      if (error.response.data.message && error.response.data.message == 'User Login Already Exists') {
        message = "Email informado já foi cadastrado";
      } else if (error.response.data.message && error.response.data.message == 'User Invalid CPF') {
        message = "CPF informado é inválido";
      } else if (error.response.data.message && error.response.data.message == 'LDAP Password Policy Exception') {
        message = "Senha não atende os requisitos da Política de Senha";
      } else if (error.response.data.message && error.response.data.message == 'Validation failed for object=\'userFirstAccess\'. Error count: 2') {
        message = 'Login informado não encontrado.';
      }

      context.commit("showmsg", {
        text: message,
        type: "error"
      });

    });
  }

};

const mutations = {
  setSession(state, data) {
    state.session = data;
  },
  setUserAuth(state, data) {
    state.userAuth = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
